<template>
    <v-container class="pa-0">
        <div v-for="(value, index) in listInvoices" :key="index">
            <v-row class="pl-2 pr-2 background-card">
                <v-col cols="12">
                    <b class="body-1 font-weight-bold" style="overflow-wrap: break-word;">{{value.itemName}}</b>
                </v-col>
            </v-row>
            <v-row class="pl-2 pr-2 background-card">
                <v-col cols="6" class="pt-0 pb-1 body-2">
                    {{$t('TotalQuantity')}}
                </v-col>
                <v-col cols="6" class="text-end pt-0 pb-1">
                    {{Intl.NumberFormat().format(value.itemQty)}}
                </v-col>
            </v-row>
            <v-row class="pl-2 pr-2 background-card font-weight-medium">
                <v-col cols="6" class="pt-0 pb-1 body-2">
                    {{$t('TicketPrice')}}
                </v-col>
                <v-col cols="6" class="text-end pt-0 pb-1 text-orange body-2">
                    <!-- <b>{{Intl.NumberFormat().format(value.cart_price_after_discount)}} {{value.cart_currency}}</b> -->
                    <b>{{ Intl.NumberFormat('de-DE', { style: 'currency', currency: value.selectedCurrency }).format(value.convertedPrice) }}</b>
                </v-col>
            </v-row>

            <v-row class="pl-2 pr-2 background-card font-weight-medium">
                <v-col cols="6" class="body-2">
                    <b>{{$t('TotalSub')}}</b>
                </v-col>
                <v-col cols="6" class="text-end body-2">
                    <!-- <b>{{ Intl.NumberFormat().format(value.cart_price_after_discount*value.cart_qty) }} {{value.cart_currency}}</b> -->
                    <b>{{ Intl.NumberFormat('de-DE', { style: 'currency', currency: value.selectedCurrency }).format(value.convertedPriceTotal) }}</b>
                </v-col>
            </v-row>
            <hr>
        </div>
        <v-row v-if="discount > 0" class="pl-2 pr-2 background-card font-weight-medium">
            <v-col cols="6" class="pt-0 pb-1 body-2">
                <b>{{$t('Discount')}}</b>
            </v-col>
            <v-col cols="6" class="text-end pt-0 pb-1 body-2">
                <b>{{ Intl.NumberFormat().format(discount) }} VND</b>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "Invoices",
    props:['listInvoices','payload','campaign_otomatis','total'],
    data: function () {
        return {
            discount:0,
            ticketType : {
                "01" : "Single Trip",
                "02" : "Round Trip",
                "03" : "Multi Trip",
                "04" : "Commuter Pass",
                "05" : "Student Pass",
            }
        }
    },
    computed: {
        campaign() {
        return this.campaign_otomatis
        },
    },
    watch:{
        campaign(){
          
            if (this.campaign.type == "Percentage") {
                this.discount = this.total * (this.campaign.value / 100)
            } else if (this.campaign.type == "Amount") {
                this.discount = this.campaign.value
            }else{
                this.discount = 0
            }
            }  
    },
    mounted(){

}}
</script>
<style scoped>
    hr {
        border: 0.5px solid #F5F5F5;
    }
    .text-orange {
        color: #F29900;
    }
    .background-card{
        background-color: rgb(255, 255, 255);
    }
</style>