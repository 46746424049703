<template>
    <v-row justify="center">
        <v-dialog
            v-model="dialog"
            persistent
            max-width="350"
        >
            <v-card>
                <v-card-title class="text-h5 text-center">
                    {{$t("PaymentDetail")}}
                </v-card-title>
                <v-card-text>
                    <v-row class="d-flex justify-space-between">
                        <v-col cols="62" class="pa-1">
                            <b>{{$t("Items")}}</b>
                        </v-col>
                        <v-col cols="6" class="pa-1 text-right">
                            <ul clas="pl-0" style="list-style-type: none;padding-left: 0;">
                                <li 
                                    v-for="(item,index) in detailTransaction.items" 
                                    :key="item.item_id">
                                    {{ index + 1 }}. {{ truncate(String(item.item_name), 20) }} ({{ Intl.NumberFormat('de-DE', { style: 'currency', currency: item.currency_code }).format(item.unit_price) }} x {{ item.qty }})
                                </li>
                            </ul>
                        </v-col>
                    </v-row>
                    <v-row class="d-flex justify-space-between">
                        <v-col cols="6" class="pa-1">
                            <b>{{$t("TotalPayment")}}</b>
                        </v-col>
                        <v-col cols="6" class="pa-1 text-right">
                            <b>{{ Intl.NumberFormat('de-DE', { style: 'currency', currency: detailTransaction.currencyCode }).format(detailTransaction.trxAmount) }}</b>
                        </v-col>
                    </v-row>
                    <v-row class="d-flex text-center">
                        <v-col cols="12" class="pa-1">
                            <b>{{$t("ConfirmationBeforePay")}}</b>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions class="pb-3">
                    <v-spacer></v-spacer>
                    <v-btn
                        color="danger"
                        @click="clickCancel"
                        small
                        rounded
                        elevation="0"
                    >
                        <span class="px-1 white--text">{{$t("Cancel")}}</span>
                    </v-btn>
                    <v-btn
                        color="primary"
                        rounded
                        elevation="0"
                        @click="clickPay"
                        small
                    >
                        <span class="px-1">{{$t("PayNow")}}</span>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
  </template>
  
<script>
export default {
    name: "PaymentConfirmation",
    props: {
        dialog: {
            /*
              set open dialog
            */
            type: Boolean,
            required: true,
        },
        onHandlerPay: {
            /*
              set handler function after click oke/ yes
            */
            type: Function,
            required: false,
        },
        onHandlerCancel: {
            /*
              set handler function after click oke/ yes
            */
            type: Function,
            required: false,
        },
        detailTransaction: {
            /*
              set handler function after click oke/ yes
            */
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            dialogs: this.dialog,
            currency: "VND"
        }
    },
    methods: {
        clickPay() {
            this.onHandlerPay();
        },
        clickCancel() {
            this.onHandlerCancel();
        },
        formatCurrency(number) {
            return new Intl.NumberFormat('en-EN').format(number).replace(/\D00$/, '');
        },
        truncate(str, n){
            return (str.length > n) ? str.slice(0, n-1) + '...' : str;
        }
    }
}
</script>