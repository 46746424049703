<template>
    <v-container>
        <payment-confirmation
            :dialog="payment_confirmation"
            :onHandlerPay="doPayment"
            :detailTransaction="modalDetailTransaction"
            :onHandlerCancel="cancelPayButton"
        />
  
        <notification-popup
            :dialog="notifFailed"
            :contentMsg="$t('notifFailedPay')"
            :headerMsg="$t('Failed')"
            :nameBtnNo="$t('labelNo')"
            :nameBtnYes="$t('Close')"
            disableBtnNo="true"
            colorIcon="danger"
            typeModal="transaction"
            Icon="mdi-close-circle"
            :onHandlerYes="buttonOkeFailed"
        />
  
        <v-row class="pl-2 pr-2 background-card mb-4">
            <v-col cols="6">
                <b>{{ $t("SubtotalForItem") }}</b>
            </v-col>
            <v-col cols="6" class="text-end pr-10">
                <b>{{ Intl.NumberFormat('de-DE', { style: 'currency', currency: selectedCurrency }).format(totalPayment) }}</b>
            </v-col>
        </v-row>
        <Invoices class="mb-4" :listInvoices="items" :total="totalAmount" :campaign_otomatis="discount_invoice" :payload="payload" />
        <v-row class="pl-2 pr-2 bg-disc-code mb-4" v-if="discountAmount > 0">
            <v-col cols="6" class="body-2">
                <b>{{ $t("Discount") }}</b>
            </v-col>
            <v-col cols="6" class="text-end body-2">
                <b>{{ '-' + Intl.NumberFormat('de-DE', { style: 'currency', currency: selectedCurrency }).format(discountAmount) }}</b>
            </v-col>
        </v-row>
        <br/>
      
        <v-row v-if="showListMethod" class="row pa-5 px-2" style="background:white">
            <v-col cols="12" class="pt-0 pb-0">
                <v-autocomplete
                    append-icon="mdi-chevron-down"
                    :label="$t('ChoosePaymentMethod')"
                    v-bind:items="payment_channels"
                    v-model="payment_channel_selected"
                    item-text="payment_channel_name"
                    item-value="payment_channel_code"
                    outlined
                    dense 
                    hide-details
                    return-object>
                    <template slot="selection" slot-scope="data">
                        <v-img maxHeight="18" maxWidth="18" :src="data.item.icon"></v-img>
                        <span class="pl-2">{{ data.item.payment_channel_name}}</span>
                    </template>
                    <template slot="item" slot-scope="data">
                        <v-img maxHeight="18" maxWidth="18" :src="data.item.icon"></v-img>
                        <span class="pl-2">{{ data.item.payment_channel_name}}</span>
                    </template>
                </v-autocomplete>
            </v-col>
            <v-form v-model="valid" v-if="showPhoneNumber">
                <v-col cols="12" class="pt-4 pb-0">
                    <v-row>
                        <v-col cols="12 pb-0 pb-0 pt-0">
                            <label class="label-content font-weight-bold">{{
                                $t("MobileNumber")
                            }}</label>
                        </v-col>
                        <v-col cols="6" class="pb-0 pt-0">
                            <v-autocomplete 
                                class="autocomplete-cid" 
                                v-model="phoneCID" 
                                type="text" 
                                :items="countries"
                                item-text="defaultPlaceholder" 
                                item-value="dialCode" 
                                placeholder="Select National"
                                name="PhoneCID" 
                                v-validate="'required'" 
                                :error-messages="errors.first('PhoneCID')" 
                                outlined
                                dense 
                                return-object
                            >
                                <template slot="item" slot-scope="data">
                                    <p class="font-weight-normal">
                                        {{ data.item.name }}
                                    </p>
                                </template>
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="6" class="pb-0 pt-0">
                            <v-text-field 
                                v-model="phoneNumber" 
                                :prefix="phoneCID.dialCode || userData.phoneCID"
                                :placeholder="$t('phoneNumber')" 
                                name="phoneNumber" 
                                v-validate="'required|min:4|max:15'"
                                :error-messages="errors.first('phoneNumber')" 
                                type="number" 
                                required 
                                outlined
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-col>
            </v-form>
        </v-row>

        <div class="footer-spacer"></div>
        <v-footer fixed app width="auto" color="white" elevation="3">
            <v-container class="mt-0 pt-0 mb-0 pb-0">
                <v-row class="ma-2 pa-0">
                    <v-col class="ma-0 pa-0 d-flex justify-start" align-self="center">
                        <h2 class="labelgrandtotal">{{ $t("Total") }}</h2>
                    </v-col>
                    <v-col class="ma-0 pa-0 d-flex justify-end" align-self="center">
                        <h2 class="grandtotal" width="auto">
                            {{ Intl.NumberFormat('de-DE', { style: 'currency', currency: selectedCurrency }).format(totalAmountToPay) }} 
                        </h2>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col
                        class="mt-0 pt-0"
                        cols="12"
                        align-self="center"
                    >
                    <ac-button 
                        :name="$t('Pay')" 
                        :on-click-handler="openPaymentConfirmation"
                        :is-disabled="disableButton || payment_channel_selected == null || (showPhoneNumber ? !this.valid : false)"
                        width="100%"
                    />
                    </v-col>
                </v-row>
            </v-container>
        </v-footer>
    </v-container>
</template>

<script>
import store from "@/store";
import Invoices from "@/components/InvoicesCheckout.vue";
import NotificationPopup from "@/components/NotificationPopup.vue";
import PaymentConfirmation from "@/components/PaymentConfirmationV2.vue";
import allCountries from "@/components/Countries";
import AcButton from "@/components/AcButton.vue";

export default {
    name: "Checkout",
    components: {
        Invoices,
        NotificationPopup,
        PaymentConfirmation,
        AcButton
    },
    data() {
        return {
            disableButton: false,
            discount: 0,
            totalPayment: 0,
            notifRoute: false,
            timestamp: "",
            selected: [],
            allSelected: false,
            notifSuccess: false,
            notifFailed: false,
            total: 0,
            routeInformation: {},
            payload: 0,
            customerID: store.state.auth.userData.customerID,
            customerUsername: store.state.auth.userData.username,
            campaign_otomatis: {},
            discount_invoice: { status: false, id: 0, type: "", value: 0, is_manual: "" },
            payment_channel_selected: null,
            payment_channels: [],
            detailTransaction: {},
            totalAmountToPay: "",
            payment_confirmation: false,
            modalDetailTransaction: {},
            payment_token: "",
            showListMethod: false,
            reveal: false,
            countries: allCountries,
            phoneCID: { "name": "Indonesia", "iso2": "ID", "flags": "🇮🇩", "dialCode": "+62", "defaultPlaceholder": "🇮🇩 Indonesia ( +62)", "priority": 0, "areaCodes": null },
            phoneNumber: '',
            showPhoneNumber: false,
            valid: false
        };
    },
    computed: {
        selectedCurrency() {
            return this.$store.state.purchaseticket.currency ? this.$store.state.purchaseticket.currency.code : ""
        },
        campaign() {
            return store.state.purchaseticket.campaign_otomatis
        },
        items() {
            return store.state.purchaseticket.cart.filter((el) => {
                return el.selected == true;
            });
        },
        discountAmount() {
            return store.state.purchaseticket.discount.value
        },
        discountData() {
            return store.state.purchaseticket.discount
        },
        totalAmount() {
            var subtotal = this.items.map(
                (itm) => itm.convertedPriceTotal
            );
            var total = subtotal.reduce((a, b) => a + b);
            return total - Number(this.discountAmount)
        },
        userData() {
            return this.$store.state.auth.userData;
        }
    },
    watch: {
        campaign() {
            if (this.campaign == undefined) {
                this.discount_invoice = { status: false, id: 0, type: "", value: 0, is_manual: "" }
            } else {
                this.discount_invoice = this.campaign
            }
        },
        items: {
            handler: function () {
                if (this.items.length == 0) {
                    this.$router.go(-1)
                }
            },
            deep: true
        },
        payment_channel_selected() {
            const itemName = this.payment_channel_selected.name

            if (["ovo", "linkaja", "dana"].find(str => itemName.toLowerCase().includes(str)) != undefined) {
                this.showPhoneNumber = true
            } else {
                this.showPhoneNumber = false
                this.phoneNumber = ''
            }
            this.valid = false
        },
        valid() {
            if (this.phoneNumber == '') {
                this.valid = false
            }
        }
    },
    methods: {
        clickRouteInformation(itemRoute) {
            this.routeInformation = itemRoute;
            this.notifRoute = true;
        },
        closeRouteInformation() {
            this.notifRoute = false;
        },
        buttonOkeFailed() {
            this.notifFailed = false;
            if (this.notifFailed == false) {
                if (this.$partnerACL.findMenu('TransactionHistory')) {
                    this.$router.push({ name: "TransactionHistory" });
                }
            }
        },
        checkout() {
            var cart = []
            for (let item of this.items) {
                cart = (cart.concat(item.customerCartIDs));
            }
            const payload = {
                customer_cart_ids: cart,
                language: this.$i18n.locale,
                price_total: this.totalPayment,
                currency_code: this.selectedCurrency,
                discount: this.discountData
            };

            this.$store
                .dispatch("purchaseticket/checkout", payload)
                .then((response) => {
                    localStorage.setItem("token", response.payment_token);
                    this.payment_token = response.payment_token
                    if (response.status == true) {
                        this.$store.dispatch("payment/paymentDetail", response.payment_token)
                            .then((response) => {
                                if (response.status_code == 200) {
                                    this.detailTransaction = response.data.payment_info
                                    this.totalAmountToPay = this.detailTransaction.payment_detail.amount
                                }
                                this.showListMethod = true
                            })
                    } else {
                        this.notifFailed = true;
                    }
                })
                .catch((err) => {
                    console.log(err)
                    // this.notifFailed = true;
                });
        },
        openPaymentConfirmation() {
            let items = this.detailTransaction.items
            let newItems = items.filter(element => element.item_name != "Transaction Fee")

            this.modalDetailTransaction.items = newItems
            this.modalDetailTransaction.trxAmount = this.detailTransaction.payment_detail.amount
            this.modalDetailTransaction.currencyCode = this.selectedCurrency

            this.togglePaymentConfirmation(true)
        },
        cancelPayButton() {
            localStorage.removeItem("trxDetail");
            this.togglePaymentConfirmation(false)
        },
        togglePaymentConfirmation(boolean) {
            this.payment_confirmation = boolean
        },
        doPayment() {
            const order_id = String(this.detailTransaction.transaction_detail.order_id).replace('ACASIA', 'TC')
            let updateInfo = {
                gross_amount: this.modalDetailTransaction.trxAmount,
                payment_channel_id: "" + this.payment_channel_selected.payment_channel_id,
                id_business_entity: "" + this.payment_channel_selected.id_business_entity,
                order_id,
                payment_token: this.detailTransaction.token,
                phone_number: this.showPhoneNumber ? (this.phoneCID.dialCode || this.userData.phoneCID) + this.phoneNumber : ''
            }

            localStorage.setItem('trxDetail', JSON.stringify(updateInfo))
            this.togglePaymentConfirmation(false)
            this.$router.push({ name: "DoPayment", params: { payment_token: this.payment_token }, });
        },
        changeTextField(val, fieldName) {
            this[fieldName] = val
        },
    },
    mounted() {
        if (Intl.NumberFormat().format(this.totalAmount) == "NaN") {
            this.$router.push({ name: "TransactionHistory" });
        }

        this.totalPayment = this.totalAmount;
        this.totalAmountToPay = this.totalPayment;
        this.checkout()

        const payload = {
            partner_id: store.state.auth.userData.partnerID
        }
        this.$store
            .dispatch("orchestrator/listPaymentChannel", payload)
            .then((response) => {
                const list = []
                for (let payment of response.data) {
                    payment.payment_channel_code = payment.payment_channel_id
                    payment.payment_channel_name = payment.name
                    payment.icon = payment.icon_url

                    list.push(payment)
                }

                this.payment_channels = list
            })
            .catch((err) => {
                console.log(err)
            })
    }
}
</script>

<style lang="scss" scoped>
.footer-spacer {
    width: 100%;
    height: 140px;
}

.v-card--reveal {
    bottom: 0;
    opacity: 1 !important;
    position: absolute;
    width: 100%;
}

.background-card {
    background-image: url("../../assets/header-decorations.svg"), linear-gradient(rgba(255, 255, 255, 0.774), rgba(255, 255, 255, 0.774)) !important;
    background-color: #ffffff !important;
    background-blend-mode: multiply;
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: 70px 70px;
    position: relative;
}

.rounded-card {
    border-radius: 15px !important;
    background: linear-gradient(180deg, #ffffff 0%, #ffe9fc 100%);
}

.btn-cart {
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    align-items: center;
    text-align: center;
    letter-spacing: 0.04em;
    text-transform: capitalize;
}

// card
/* If the screen size is 673px or more, set the font-size of <div> to 80px */
// @media only screen and (max-width: 600px) {
.component-1 {
    padding: 10px 14px 10px 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-image: url("~@/assets/buy-ticket/bg-card.svg");
}

.cardCart {
    background-color: #f5fdf7;
    display: flex;
    flex-wrap: nowrap;
}

.route-1 {
    color: rgba(134, 134, 134, 1);
    margin-bottom: 1px;
    margin-left: 150px;
}

.flex-wrapper-three {
    margin-top: -10px;
    padding: 0 0 0 25px;
    display: flex;
    align-items: center;
}

.bth {
    font-size: 24px;
    color: rgba(72, 158, 75, 1);

    &:not(:last-of-type) {
        margin-right: 30px;
    }
}

.relative-wrapper-one {
    margin-right: 31px;
    position: relative;
}

.vector {
    position: relative;
}

.group {
    position: absolute;
    left: 55px;
    top: -1px;
    transform: rotate(180deg);
}

.flex-wrapper-four {
    margin-left: 10px;
    margin-bottom: -40px;
}

.bn-thanh {
    margin-top: -15px;
    margin-left: 15px;
    font-size: 10px;
    color: rgba(75, 177, 78, 1);
    right: 122px;

    &:not(:last-of-type) {
        margin-right: 135px;
    }
}

.bn-bbc {
    margin-top: -30px;
    font-size: 10px;
    color: rgba(75, 177, 78, 1);
    margin-right: -100px;
    text-align: right;
}

.vector-7 {
    margin-top: 35px;
    margin-left: 9px;
    margin-bottom: 9px;
}

.flex-wrapper-five {
    padding: 0 0 0 17px;
    display: flex;
    align-items: flex-start;
}

.flex-wrapper-six {
    margin-right: 43px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.route-information {
    // @include roboto-12-medium;
    font-size: 12px;
    color: rgba(94, 215, 153, 1);
    text-decoration: underline;
    margin-top: -2px;
    margin-bottom: -2px;
}

.flat-fare-ticket-is-valid-for-24-hours {
    // @include roboto-8-light;
    font-size: 8px;
    color: rgba(75, 177, 78, 1);
    font-style: italic;
}

.num-135000-v-nd {
    // @include roboto-14-medium;
    font-size: 12px;
    color: rgba(242, 153, 0, 1);
    margin-top: 4px;
}

.group-18785 {
    border-radius: 23px;
    height: 29px;
    width: 80px;
    padding: 2px 6px 2px 4px;
    position: relative;
    border: 1px solid rgba(75, 177, 78, 0.35);
}

.flex-wrapper-one {
    background-color: #ffffff;
    border-radius: 50%;
    padding: 11px 10px 10px 10px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    position: absolute;
    left: 41px;
    top: -1px;
    border: 1px solid rgba(75, 177, 78, 1);
}

.flex-wrapper-two {
    background-color: #ffffff;
    border-radius: 50%;
    padding: 11px 10px 10px 10px;
    display: flex;
    height: 30px;
    width: 30px;
    align-items: center;
    position: absolute;
    left: -10px;
    top: -1px;
    border: 1px solid rgba(75, 177, 78, 1);
}

.hyphen {
    width: 5px;
    height: 2px;
    background-color: rgba(75, 177, 78, 1);
}

.label {
    width: 64px;
    // @include roboto-10-bold;
    color: rgba(75, 177, 78, 1);
    text-align: center;
    position: relative;
}

.btn-plus {
    top: -5px;
    height: 30px;
    width: 30px;
    left: 45px;
}

.btn-minus {
    top: -5px;
    height: 30px;
    width: 30px;
    left: -40px;
}

.icon-plus {
    height: 6px;
    width: 1px;
    left: -1px;
}

.icon-minus {
    height: 6px;
    width: 1px;
    left: -1px;
}

.input-label {
    top: -27px;
}

.onHover {
    cursor: pointer;
}

.delete-card {
    padding-left: 10px;
}

.bg-disc-code {
    background-color: #ffffff;
}

.autocomplete-cid {
    text-emphasis-color: #ffffff;
}
</style>
